// Define defaults for each variable.
$base-font-family: Bitter, "Apple SD Gothic Neo", AppleGothic, NanumBarunGothic,
  "Malgun Gothic", Dotum, sans-serif !default;
$monospace-font-family: Monaco, Menlo, Consolas, "Courier New", DotumChe,
  monospace !default;
$base-font-size: 24px !default;
$base-font-weight: 400 !default;
$small-font-size: $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit: 30px !default;

$text-color: #3c3836;
$background-color: #f5f2ed;
$brand-color: #cc241d;

$grey-color: #928374;
$grey-color-light: #e1dcd4;
$grey-color-dark: #3c3836;

// Width of the content area
$content-width: 800px !default;

$on-palm: 600px !default;
$on-laptop: 800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

// Import partials.
@import "whiteglass/base", "whiteglass/layout", "whiteglass/syntax-highlighting";
